import Pone from "~image/portfolio/1.png"
import Ptwo from "~image/portfolio/2.png"
import Pthree from "~image/portfolio/3.png"
import Pfour from "~image/portfolio/4.png"
import Pfive from "~image/portfolio/5.png"
import Psix from "~image/portfolio/6.png"
import Pseven from "~image/portfolio/7.png"
import Peight from "~image/portfolio/8.png"
import Pnine from "~image/portfolio/9.png"
import Pten from "~image/portfolio/10.png"

const portfolioData = [
  {
    image: Ptwo,
    title: "LONO",
    titleSmall: "Lono Inc, Canada",
    id: "2",
    tagName: ["all", "marketing", "development"],
    url: "lono/",
  },
  {
    image: Pthree,
    title: "dPassive Finance",
    titleSmall: "dPassive Finance",
    id: "3",
    tagName: ["all", "design", "marketing"],
    url: "dpassive-finance/",
  },
  {
    image: Pfive,
    title: "Trading Insight App",
    titleSmall: "TradingInsight.net",
    id: "4",
    tagName: ["all", "seo"],
    url: "trading-insight-app/",
  },
  {
    image: Psix,
    title: "Trading Insight Web",
    titleSmall: "TradingInsight.net",
    id: "5",
    tagName: ["all", "seo"],
    url: "trading-insight-web/",
  },
  {
    image: Pseven,
    title: "NFT  Flutter Theme",
    titleSmall: "Axcer",
    id: "6",
    tagName: ["all", "seo"],
    url: "nft-flutter-theme/",
  },
  {
    image: Peight,
    title: "So Sri Lanka",
    titleSmall: "Sri Lanka Tourism Board",
    id: "7",
    tagName: ["all", "seo"],
    url: "so-sri-lanka/",
  },
  {
    image: Pnine,
    title: "Minty",
    titleSmall: "Axcer",
    id: "8",
    tagName: ["all", "seo"],
    url: "minty/",
  },
  {
    image: Pten,
    title: "Mb Parts",
    titleSmall: "Mb Parts",
    id: "9",
    tagName: ["all", "seo"],
    url: "mb-parts/",
  },
  {
    image: Pfour,
    title: "Rich Me",
    titleSmall: "Rich Me",
    id: "10",
    tagName: ["all", "seo"],
    url: "rich-me/",
  },
]
export default portfolioData
