import React from 'react'
import BreadCrumb from './style'
import { StaticImage as Img } from 'gatsby-plugin-image'
import { Col, Row, Container } from 'react-bootstrap'
import { SuperTag } from '~components'
import ReactTypingEffect from 'react-typing-effect'
export default function BreadCrumbSection({ colClasses, title, text, ...rest }) {
  return (
    <BreadCrumb backgroundColor="#f3f4f6" {...rest}>
      <Container>
        <Row className="row justify-content-center text-center">
          <Col className={colClasses ? colClasses : "col-xl-7"}>
            <BreadCrumb.Title as="h2">

              {title}


            </BreadCrumb.Title>
            <BreadCrumb.Text>
              Bring your &nbsp;
              <span style={{ color: "#8a55df" }} >
                <ReactTypingEffect
                  text={["idea into finger tips..", "idea into finger tips.."]}
                  className=""
                  speed="150"
                  eraseSpeed="100"
                  typingDelay="400"
                  eraseDelay="800"
                  cursorClassName="typed-cursor"
                />
              </span>
            </BreadCrumb.Text>

          </Col>
        </Row>
      </Container>
      <BreadCrumb.Image>
        <Img className="w-100" src="../../../../assets/image/mixed/inner-banner-shape.png" alt="shape" layout="fullWidth" placeholder="blurred" />
      </BreadCrumb.Image>
    </BreadCrumb>
  )
}